import { Component, OnInit } from '@angular/core';
import { BitfDynamicLocaleService } from '@common/libs/bitforce/core/services/locale/bitf-dynamic-locale.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  iubendaPrivacyData = {
    it: { id: '39692961', title: 'Privacy Policy', cookieTitle: 'Cookie Policy', bannerCode: 'it' },
    fr: {
      id: '39297569',
      title: 'Politique de confidentialité',
      cookieTitle: 'Politique relative aux Cookies',
      bannerCode: 'fr',
    },
    es: {
      id: '83921498',
      title: 'Política de Privacidad',
      cookieTitle: 'Política de Cookies',
      bannerCode: 'es',
    },
    en: { id: '98753195', title: 'Privacy Policy', cookieTitle: 'Cookie Policy', bannerCode: 'en-GB' },
    de: { id: '70982919', title: 'Datenschutzerklärung', cookieTitle: 'Cookie-Richtlinie', bannerCode: 'de' },
  };
  selectedPrivacyData: any = this.iubendaPrivacyData.en;

  constructor(private localeService: BitfDynamicLocaleService) {}

  ngOnInit(): void {
    this.selectedPrivacyData =
      this.iubendaPrivacyData[this.localeService.locale.code] || this.iubendaPrivacyData.en;

    this.setCookieBanner();
  }

  setCookieBanner() {
    window['_iub'] = window['_iub'] || [];
    window['_iub']['csConfiguration'] = {
      ccpaAcknowledgeOnDisplay: true,
      consentOnContinuedBrowsing: false,
      countryDetection: true,
      enableCcpa: true,
      floatingPreferencesButtonDisplay: 'bottom-right',
      invalidateConsentWithoutLog: true,
      perPurposeConsent: true,
      siteId: 2749784,
      whitelabel: false,
      cookiePolicyId: this.selectedPrivacyData.id,
      lang: this.selectedPrivacyData.bannerCode,
      banner: {
        acceptButtonCaptionColor: '#FFFFFF',
        acceptButtonColor: '#0073CE',
        acceptButtonDisplay: true,
        backgroundColor: '#FFFFFF',
        backgroundOverlay: true,
        brandBackgroundColor: '#FFFFFF',
        brandTextColor: '#000000',
        closeButtonDisplay: false,
        customizeButtonCaptionColor: '#4D4D4D',
        customizeButtonColor: '#DADADA',
        customizeButtonDisplay: true,
        explicitWithdrawal: true,
        listPurposes: true,
        logo: null,
        position: 'bottom',
        rejectButtonCaptionColor: '#FFFFFF',
        rejectButtonColor: '#0073CE',
        rejectButtonDisplay: true,
        textColor: '#000000',
      },
    };

    const stub = document.createElement('script');
    stub.setAttribute('type', 'text/javascript');
    stub.setAttribute('src', '//cdn.iubenda.com/cs/ccpa/stub.js');
    document.body.appendChild(stub);

    const iubenda_cs = document.createElement('script');
    iubenda_cs.setAttribute('type', 'text/javascript');
    iubenda_cs.setAttribute('src', '//cdn.iubenda.com/cs/iubenda_cs.js');
    document.body.appendChild(iubenda_cs);
  }
}
