<div class="brand-block d-flex"
  [ngClass]="{'flex-row-reverse': textSide === 'right' && !storeService.store.activeBreakpoints.isHandsetLayout, 'flex-column-reverse': storeService.store.activeBreakpoints.isHandsetLayout}">
  <div>
    <bitf-image class="logo"
      placeholderSrc="/assets/img/placeholder-image.png"
      [hidden]="storeService.store.activeBreakpoints.isHandsetLayout"
      [sources]="{
      'xs':{'1x':brand?.logo_black?.mobile, '2x':brand?.logo_black?.mobile_15 },
      'lg':{'1x':brand?.logo_black?.desktop, '2x':brand?.logo_black?.desktop_15 }
    }">
    </bitf-image>
    <div class="description">
      {{brand?.description}}
    </div>
  </div>
  <div class="image-side">
    <bitf-image class="logo"
      placeholderSrc="/assets/img/placeholder-image.png"
      [hidden]="!storeService.store.activeBreakpoints.isHandsetLayout"
      [sources]="{
      'xs':{'1x':brand?.logo_black?.mobile, '2x':brand?.logo_black?.mobile_15, 'objectFit':'cover' },
      'md':{'1x':brand?.logo_black?.desktop, '2x':brand?.logo_black?.desktop_15, 'objectFit':'cover' }
    }">
    </bitf-image>
    <div class="cover-container">
      <bitf-image class="cover"
        placeholderSrc="/assets/img/placeholder-image.png"
        [sources]="{
          'xs':{'1x':brand?.image?.mobile, '2x':brand?.image?.mobile_15, 'objectFit':'cover' },
          'md':{'1x':brand?.image?.desktop, '2x':brand?.image?.desktop_15, 'objectFit':'cover' }
        }">
      </bitf-image>
    </div>
  </div>
</div>
<div class="d-flex align-items-center justify-content-center">
  <a mat-button
    [ngStyle]="{'border-color': buttonBorderColor}"
    [routerLink]="['/brand', brand.name, brand.id]"
    class="mks-button">Learn more</a>
</div>