<div class="sidenav sidenav-sm h-100">
  <mat-toolbar class="h-auto fixed-top"
    color="primary">
    <div class="w-100 d-flex align-items-center justify-content-center">
      <img class="logo"
        src="assets/img/logo_header.png" />
      <button mat-icon-button
        class="position-absolute right-0"
        (click)="closeSidenav()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <div class="h-100 text-white d-flex flex-column justify-content-center align-items-center pt-3">
    <a class="menu-link"
      (click)="closeSidenav()"
      [routerLink]="['/']">
      <h4>Home</h4>
    </a>

    <a class="menu-link"
      (click)="closeSidenav()"
      [routerLink]="['about-us']">
      <h4>About us</h4>
    </a>

    <a class="menu-link"
      *ngFor="let brand of brands"
      (click)="closeSidenav()"
      [routerLink]="['brand', brand.name, brand.id]">
      <h4>{{brand.name}}</h4>
    </a>
  </div>
</div>