<mat-toolbar class="h-auto flex-column border-bottom"
  color="primary">
  <div class="w-100 d-flex align-items-center justify-content-center">
    <button mat-icon-button
      (click)="onToggleMenuButton()"
      *ngIf="storeService.store.activeBreakpoints.isHandsetLayout"
      class="position-absolute left-0">
      <mat-icon>menu</mat-icon>
    </button>
    <img class="logo pointer"
      [routerLink]="['/']"
      src="assets/img/logo_header.png" />
  </div>

  <div class="menu-list w-100 d-flex flex-wrap align-items-center justify-content-center"
    *ngIf="!storeService.store.activeBreakpoints.isHandsetLayout">
    <a class="menu-link"
      [routerLink]="['/']">
      <span>Home</span>
    </a>

    <a class="menu-link"
      [routerLink]="['about-us']">
      <span>About us</span>
    </a>

    <a class="menu-link"
      *ngFor="let brand of storeService.store.brands"
      [routerLink]="['brand', brand.name, brand.id]">
      <span>{{brand.name}}</span>
    </a>
  </div>
</mat-toolbar>