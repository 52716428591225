import { Component, OnInit } from '@angular/core';
import { Brand } from '@common/core/models';
import { BitfMatSidenavGlobalService } from '@common/libs/bitforce/core/services/sidenav/material/bitf-mat-sidenav-global.service';
import { EBitfCloseEventStatus } from '@web/enums';

@Component({
  selector: 'mks-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  brands: Brand[] = [];

  constructor(private bitfMatSidenavService: BitfMatSidenavGlobalService) {}

  ngOnInit(): void {
    this.brands = this.bitfMatSidenavService.componentRef.instance['brands'];
  }

  closeSidenav() {
    this.bitfMatSidenavService.close({ status: EBitfCloseEventStatus.CLOSE });
  }
}
