<footer class="footer d-flex flex-column align-items-center justify-content-center">
  <img class="logo"
    src="assets/img/logo_footer.svg" />
  <span class="text-center text-white mb-3">
    Copyright 2022 Markwins Beauty Brands, Inc. -
    <a href="https://www.iubenda.com/privacy-policy/{{selectedPrivacyData?.id}}"
      class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
      [title]="selectedPrivacyData?.title">{{selectedPrivacyData?.title}}</a>
    <span class=" ml-2">
      <a href="https://www.iubenda.com/privacy-policy/39692961/cookie-policy"
        class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe"
        [title]="selectedPrivacyData?.cookieTitle ">{{selectedPrivacyData?.cookieTitle}}</a>
    </span>
  </span>
</footer>