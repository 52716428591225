import { Injectable, Injector } from '@angular/core';

import { gql } from 'apollo-angular';

import { BitfGraphQlService } from '@bitf/services/graph-ql/bitf-graph-ql.service';

import { IBitfGraphQlRequest } from '@interfaces';
import { Product } from '@common/core/models';

@Injectable({
  providedIn: 'root',
})
// NOTE: Add methods following CRUD order
export class ProductsService extends BitfGraphQlService {
  constructor(public injector: Injector) {
    super(injector);
  }

  getProduct(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.modelMapper = 'getProduct';
    requestParams.query = gql`
      query Query($id: Int) {
        getProduct(id: $id, defaultLanguage: "en") {
          title
          frontImage {
            image {
              filename
              desktop: fullpath(thumbnail: "product_desktop")
              desktop_15: fullpath(thumbnail: "product_desktop_15")
              mobile: fullpath(thumbnail: "product_mobile")
              mobile_15: fullpath(thumbnail: "product_mobile_15")
              mimetype
            }
          }
          primaryPackaging {
            ... on fieldcollection_ProductPackaging {
              material
              sortingDetails
              material_fr: material(language: "fr")
              sortingDetails_fr: sortingDetails(language: "fr")
              material_it: material(language: "it")
              sortingDetails_it: sortingDetails(language: "it")
            }
          }
          secondaryPackaging {
            ... on fieldcollection_ProductPackaging {
              material
              sortingDetails
              material_fr: material(language: "fr")
              sortingDetails_fr: sortingDetails(language: "fr")
              material_it: material(language: "it")
              sortingDetails_it: sortingDetails(language: "it")
            }
          }
        }
      }
    `;

    return super.query<Product>(requestParams);
  }

  // TODO pagination
  getProductListing(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.modelMapper = 'getProductListing';
    requestParams.query = gql`
      query Query($first: Int, $after: Int, $filter: String) {
        getProductListing(first: $first, after: $after, filter: $filter) {
          edges {
            node {
              id
              eu_item: sku
              ean
              title
              shadeName
              segment: brandSubcategory {
                ... on object_BrandSubcategory {
                  description
                }
              }
            }
          }
          totalCount
        }
      }
    `;

    return super.query<Product[]>(requestParams);
  }
}
